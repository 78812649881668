import React from "react";
import "./Loader.css";

const Loader = () => {
    return(
        <div className="loader">
            <div className="loaderloader"></div>
        </div>
    )
}

export default Loader;