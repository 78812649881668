import logo from './logo.svg';
import Home from "./Components/Home/Home";
import './App.css';
import Loader from './Components/Loader/Loader';

const App = () => {
  
  return(
      <Home />
  )
}

export default App;
