import React from "react";
import "./ContactUsHome.css";
import { StartingPage } from "../../Home/StartingPage/StartingPage";

const ContactUsHome = () => {
        return(
            <>
                <div className="ContactUsHome">
                    <h1>CONTACT US</h1>
                    <br />
                    {/* <h1 className="ContactUsHomeHead">How can we be of service?</h1> */}
                    <p className="ContactUsHomePara">Tilive is one of the leading consulting firms in the areas of Biz development, Marketing,  Sourcing & Supply chain management, Manufacturing, and supplier of OE and Aftermarket parts to domestic and international markets.</p>
                </div>
            </>
        )
}

export default ContactUsHome;