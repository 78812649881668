import React from "react";
import "./ClientsWhyUs.css";

const ClientsWhyUs = () => {
    return(
        <div className="ClientsWhyUsP">
            <div className="ClientsWhyUsPIn">

                <h2 className="ClientsWhyUsHead">Why Choose Tilive International LLP?</h2>
                <br />
                <br />
                <p className="ClientsWhyUsPP"><span className="ClientsWhyUsPPBold">Meaningful Work:</span> At Tilive International LLP, you&#39;ll have the opportunity to work on impactful projects
that make a difference. We tackle complex challenges and provide innovative solutions that have a
positive impact on our clients and society as a whole.</p>
                <br />
                <p className="ClientsWhyUsPP"><span className="ClientsWhyUsPPBold">Professional Development:</span> We foster a culture of continuous learning and growth. We provide our
employees with the resources, tools, and support needed to enhance their skills and expand their
knowledge. You&#39;ll have access to training programs, mentorship opportunities, and professional
development initiatives to help you reach your full potential.</p>
                <br />

                <p className="ClientsWhyUsPP"><span className="ClientsWhyUsPPBold">Global Reach:</span> With a global presence, Tilive International LLP offers exciting opportunities to work on
projects around the world. You&#39;ll have the chance to collaborate with international teams, broaden your
perspective, and contribute to projects that have a global impact..</p>
                <br />

                <p className="ClientsWhyUsPP"><span className="ClientsWhyUsPPBold">Collaborative Environment:</span> We believe in the power of teamwork. At Tilive International LLP, you&#39;ll
collaborate with diverse and talented individuals from various backgrounds, disciplines, and cultures.

                
We value open communication, idea sharing, and collaboration to foster creativity and achieve
exceptional results.</p>
                <br />

                <p className="ClientsWhyUsPP"><span className="ClientsWhyUsPPBold">Work-Life Balance:</span> We understand the importance of maintaining a healthy work-life balance. At Tilive
International LLP, we strive to create an inclusive and flexible work environment that promotes well-
being and supports our employees&#39; personal and professional commitments.</p>
            </div>
        </div>
    )
}

export default ClientsWhyUs;