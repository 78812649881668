import React from "react";

const BuyOnline = () => {
    return(
        <div>
            <h1>This is BuyOnline</h1>
        </div>
    )
}

export default BuyOnline;