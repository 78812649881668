import React from "react";
import "./WeAre.css";

const WeAre = () => {
    return(
        <div className="WeAre">
            <h2 className="WeAreHead">We Are</h2>
                <div className="WeAreMainContentImg"><img src="https://resume-example.com/wp-content/uploads/2021/03/multiethnic-business-team-working-with-blueprint-on-meeting-1024x684.jpg" /></div>
            <div className="WeAreMainContent">
                <div className="WeAreMainContentDes">
                    <p>Tilive International LLP is a trusted and reputable B2B business consultancy established on the principles of Trust, Integrity, and
Loyalty towards all our stakeholders.</p>
                    <br />
                    <p>With expertise in various domains, we specialize in
providing comprehensive solutions in Business
Development, B2B Marketing, establishing Sales and Distribution
channel, Sourcing and Supply Chain
Management, Manufacturing and General trading.</p>
                    <br />
                    <p>At Tilive International LLP, we believe in building long-term
relationships with our clients, based on mutual trust and
collaboration. Our dedicated team of professionals
possesses extensive industry knowledge and experience,
enabling us to deliver tailored solutions that meet the
unique requirements of each client.</p>
                </div>
            </div>
        </div>
    )
}

export default WeAre;